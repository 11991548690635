import React from "react";
import { useEffect, useState } from "react";
// import mainscript from "./ichingScript.js"
import * as styles from "./iching.module.css";

function numbersToName(number) {
  if (number === "111") {
    return "Heaven";
  } else if (number === "011") {
    return "Lake";
  } else if (number === "101") {
    return "Fire";
  } else if (number === "001") {
    return "Thunder";
  } else if (number === "110") {
    return "Wind";
  } else if (number === "010") {
    return "Water";
  } else if (number === "100") {
    return "Mountain";
  } else if (number === "000") {
    return "Earth";
  }
} //numbersToName

const Iching = () => {
  const [hexagramNameState, setHexagramNameState] = useState("");
  const [hexagramExtraTextState, setHexagramExtraTextState] = useState("");
  const [topName, setTopName] = useState("");
  const [bottomName, setBottomName] = useState("");

  const [lineOneYin, setLineOneYin] = useState("");
  const [lineTwoYin, setLineTwoYin] = useState("");
  const [lineThreeYin, setLineThreeYin] = useState("");
  const [lineFourYin, setLineFourYin] = useState("");
  const [lineFiveYin, setLineFiveYin] = useState("");
  const [lineSixYin, setLineSixYin] = useState("");

  useEffect(() => {
    // var one = document.getElementById("one")
    // var two = document.getElementById("two")
    // var three = document.getElementById("three")
    // var four = document.getElementById("four")
    // var five = document.getElementById("five")
    // var six = document.getElementById("six")

    //var lineArray = [one, two, three, four, five, six]

    var choiceArray = [];

    for (var i = 0; i < 6; i++) {
      choiceArray.push(Math.floor(Math.random() * 2));
    }

    console.log(choiceArray);

    if (choiceArray[0] === 0) {
      setLineOneYin("yin");
    }
    if (choiceArray[1] === 0) {
      setLineTwoYin("yin");
    }

    if (choiceArray[2] === 0) {
      setLineThreeYin("yin");
    }

    if (choiceArray[3] === 0) {
      setLineFourYin("yin");
    }

    if (choiceArray[4] === 0) {
      setLineFiveYin("yin");
    }

    if (choiceArray[5] === 0) {
      setLineSixYin("yin");
    }

    if (choiceArray[0] === 1) {
      setLineOneYin("yang");
    }
    if (choiceArray[1] === 1) {
      setLineTwoYin("yang");
    }

    if (choiceArray[2] === 1) {
      setLineThreeYin("yang");
    }

    if (choiceArray[3] === 1) {
      setLineFourYin("yang");
    }

    if (choiceArray[4] === 1) {
      setLineFiveYin("yang");
    }

    if (choiceArray[5] === 1) {
      setLineSixYin("yang");
    }

    // for (var j = 0; j < 6; j++) {
    //   if (choiceArray[j] === 0) {
    //     lineArray[j].classList.add("yin")
    //   }
    // }

    var topTrigram = choiceArray[0] + "" + choiceArray[1] + "" + choiceArray[2];
    var bottomTrigram =
      choiceArray[3] + "" + choiceArray[4] + "" + choiceArray[5];

    var topTrigramName = numbersToName(topTrigram);
    var bottomTrigramName = numbersToName(bottomTrigram);

    var hexagramNumber = topTrigram + "" + bottomTrigram;

    var hexagramName = [
      "THE CREATIVE HEAVEN",
      "THE RECEPTIVE EARTH",
      "DIFFICULTY AT THE BEGINNING",
      "YOUTHFUL FOLLY",
      "WAITING",
      "CONFLICT",
      "THE ARMY",
      "HOLDING TOGETHER",
      "SMALL TAMING",
      "TREADING",
      "PEACE",
      "STANDSTILL",
      "FELLOWSHIP",
      "GREAT POSSESSION",
      "MODESTY",
      "ENTHUSIASM",
      "FOLLOWING",
      "WORK ON THE DECAYED",
      "APPROACH",
      "CONTEMPLATION",
      "BITING THROUGH",
      "GRACE",
      "SPLITTING APART",
      "RETURN",
      "INNOCENCE",
      "GREAT TAMING",
      "MOUTH CORNERS",
      "GREAT PREPONDERANCE",
      "ABYSMAL WATER",
      "CLINGING FIRE",
      "INFLUENCE",
      "DURATION",
      "RETREAT",
      "GREAT POWER",
      "PROGRESS",
      "DARKENING OF THE LIGHT",
      "THE FAMILY",
      "OPPOSITION",
      "OBSTRUCTION",
      "DELIVERANCE",
      "DECREASE",
      "INCREASE",
      "BREAKTHROUGH",
      "COMING TO MEET",
      "GATHERING TOGETHER",
      "PUSHING UPWARD",
      "OPPRESSION",
      "THE WELL",
      "REVOLUTION",
      "THE CAULDRON",
      "THE AROUSING THUNDER",
      "THE KEEPING STILL MOUNTAIN",
      "DEVELOPMENT",
      "THE MARRYING MAIDEN",
      "ABUNDANCE",
      "THE WANDERER",
      "THE GENTLE WIND",
      "THE JOYOUS LAKE",
      "DISPERSION",
      "LIMITATION",
      "INNER TRUTH",
      "SMALL PREPONDERANCE",
      "AFTER  COMPLETION",
      "BEFORE COMPLETION",
    ];

    var extraTextArray = [
      "Hexagram 1: Force, the creative, strong action, the key",
      "Hexagram 2: Field, the receptive, acquiescence, the  flow ",
      "Hexagram 3: Sprouting, gathering support, hoarding",
      "Hexagram 4: Enveloping, the young shoot, discovering",
      "Hexagram 5: Attending, moistened, arriving",
      "Hexagram 6: Arguing, lawsuit",
      "Hexagram 7: Leading, the troops",
      "Hexagram 8: Grouping, alliance",
      "Hexagram 9: Small Accumulating, small harvest",
      "Hexagram 10: Treading, continuing",
      "Hexagram 11: Pervading, peace, greatness",
      "Hexagram 12: Obstruction, stagnation, selfish persons",
      "Hexagram 13: Concording People, gathering people",
      "Hexagram 14: possession in great measure",
      "Hexagram 15: Humbling",
      "Hexagram 16: Providing-For, excess",
      "Hexagram 17",
      "Hexagram 18: Correcting, work on what has been spoiled, decaying, branch",
      "Hexagram 19: Nearing, the forest",
      "Hexagram 20: Viewing, looking up",
      "Hexagram 21: Gnawing Bite, biting and chewing",
      "Hexagram 22: Adorning, luxuriance",
      "Hexagram 23: Stripping, flaying",
      "Hexagram 24: The turning point",
      "Hexagram 25: the unexpected, pestilence",
      "Hexagram 26: Great Accumulating, great storage, potential energy",
      "Hexagram 27: Swallowing, jaws, comfort/security",
      "Hexagram 28: Great Exceeding, great surpassing, critical mass",
      "Hexagram 29: the abyss, repeated entrapment",
      "Hexagram 30: Radiance, the clinging, fire, the net",
      "Hexagram 31: Conjoining, feelings",
      "Hexagram 32: Persevering, constancy",
      "Hexagram 33: Retiring, yielding",
      "Hexagram 34: Great Invigorating, the power of the great, great maturity",
      "Hexagram 35: Prospering",
      "Hexagram 36: brilliancce injured, intelligence hidden",
      "Hexagram 37: Dwelling People, family members",
      "Hexagram 38: Polarizing, perversion",
      "Hexagram 39: Limping, afoot",
      "Hexagram 40: Taking-Apart, untangled",
      "Hexagram 41: Diminishing",
      "Hexagram 42: Augmenting",
      "Hexagram 43: Displacement, resoluteness, parting",
      "Hexagram 44: Coupling, meeting",
      "Hexagram 45: Clustering, gathering together, finished",
      "Hexagram 46: Ascending",
      "Hexagram 47: Confining, exhausting, entangled",
      "Hexagram 48: Welling",
      "Hexagram 49: Skinning, molting, the bridle",
      "Hexagram 50: Holding",
      "Hexagram 51: Shake, shock, thunder",
      "Hexagram 52: Bound, stilling",
      "Hexagram 53: Infiltrating, development (gradual process), advancement",
      "Hexagram 54: The marrying maiden, returning maiden",
      "Hexagram 55: Abundance, fullness",
      "Hexagram 56: Sojourning, traveling",
      "Hexagram 57: Ground, the gentle wind, calculations",
      "Hexagram 58: Open, usurption",
      "Hexagram 59: Dissolution, dispersal",
      "Hexagram 60: Articulating, moderation",
      "Hexagram 61: Center Return",
      "Hexagram 62: Small Exceeding, small surpassing",
      "Hexagram 63: Already completed, already done",
      "Hexagram 64: Before completion, not yet ocmpleted",
    ];

    var extraText = "";

    function hexagramNumberTohexagramName() {
      if (hexagramNumber === "111111") {
        extraText = extraTextArray[0];
        return hexagramName[0];
      } else if (hexagramNumber === "000000") {
        extraText = extraTextArray[1];
        return hexagramName[1];
      } else if (hexagramNumber === "010001") {
        extraText = extraTextArray[2];
        return hexagramName[2];
      } else if (hexagramNumber === "100010") {
        extraText = extraTextArray[3];
        return hexagramName[3];
      } else if (hexagramNumber === "010111") {
        extraText = extraTextArray[4];
        return hexagramName[4];
      } else if (hexagramNumber === "111010") {
        extraText = extraTextArray[5];
        return hexagramName[5];
      } else if (hexagramNumber === "000010") {
        extraText = extraTextArray[6];
        return hexagramName[6];
      } else if (hexagramNumber === "010000") {
        extraText = extraTextArray[7];
        return hexagramName[7];
      } else if (hexagramNumber === "110111") {
        extraText = extraTextArray[8];
        return hexagramName[8];
      } else if (hexagramNumber === "111011") {
        extraText = extraTextArray[9];
        return hexagramName[9];
      } else if (hexagramNumber === "000111") {
        extraText = extraTextArray[10];
        return hexagramName[10];
      } else if (hexagramNumber === "111000") {
        extraText = extraTextArray[11];
        return hexagramName[11];
      } else if (hexagramNumber === "111101") {
        extraText = extraTextArray[12];
        return hexagramName[12];
      } else if (hexagramNumber === "101111") {
        extraText = extraTextArray[13];
        return hexagramName[13];
      } else if (hexagramNumber === "000100") {
        extraText = extraTextArray[14];
        return hexagramName[14];
      } else if (hexagramNumber === "001000") {
        extraText = extraTextArray[15];
        return hexagramName[15];
      } else if (hexagramNumber === "011001") {
        extraText = extraTextArray[16];
        return hexagramName[16];
      } else if (hexagramNumber === "100110") {
        extraText = extraTextArray[17];
        return hexagramName[17];
      } else if (hexagramNumber === "000011") {
        extraText = extraTextArray[18];
        return hexagramName[18];
      } else if (hexagramNumber === "110000") {
        extraText = extraTextArray[19];
        return hexagramName[19];
      } else if (hexagramNumber === "101001") {
        extraText = extraTextArray[20];
        return hexagramName[20];
      } else if (hexagramNumber === "100101") {
        extraText = extraTextArray[21];
        return hexagramName[21];
      } else if (hexagramNumber === "100000") {
        extraText = extraTextArray[22];
        return hexagramName[22];
      } else if (hexagramNumber === "000001") {
        extraText = extraTextArray[23];
        return hexagramName[23];
      } else if (hexagramNumber === "111001") {
        extraText = extraTextArray[24];
        return hexagramName[24];
      } else if (hexagramNumber === "100111") {
        extraText = extraTextArray[25];
        return hexagramName[25];
      } else if (hexagramNumber === "100001") {
        extraText = extraTextArray[26];
        return hexagramName[26];
      } else if (hexagramNumber === "011110") {
        extraText = extraTextArray[27];
        return hexagramName[27];
      } else if (hexagramNumber === "010010") {
        extraText = extraTextArray[28];
        return hexagramName[28];
      } else if (hexagramNumber === "101101") {
        extraText = extraTextArray[29];
        return hexagramName[29];
      } else if (hexagramNumber === "011100") {
        extraText = extraTextArray[30];
        return hexagramName[30];
      } else if (hexagramNumber === "001110") {
        extraText = extraTextArray[31];
        return hexagramName[31];
      } else if (hexagramNumber === "111100") {
        extraText = extraTextArray[32];
        return hexagramName[32];
      } else if (hexagramNumber === "001111") {
        extraText = extraTextArray[33];
        return hexagramName[33];
      } else if (hexagramNumber === "101000") {
        extraText = extraTextArray[34];
        return hexagramName[34];
      } else if (hexagramNumber === "000101") {
        extraText = extraTextArray[35];
        return hexagramName[35];
      } else if (hexagramNumber === "110101") {
        extraText = extraTextArray[36];
        return hexagramName[36];
      } else if (hexagramNumber === "101011") {
        extraText = extraTextArray[37];
        return hexagramName[37];
      } else if (hexagramNumber === "010100") {
        extraText = extraTextArray[38];
        return hexagramName[38];
      } else if (hexagramNumber === "001010") {
        extraText = extraTextArray[39];
        return hexagramName[39];
      } else if (hexagramNumber === "100011") {
        extraText = extraTextArray[40];
        return hexagramName[40];
      } else if (hexagramNumber === "110001") {
        extraText = extraTextArray[41];
        return hexagramName[41];
      } else if (hexagramNumber === "011111") {
        extraText = extraTextArray[42];
        return hexagramName[42];
      } else if (hexagramNumber === "111110") {
        extraText = extraTextArray[43];
        return hexagramName[43];
      } else if (hexagramNumber === "011000") {
        extraText = extraTextArray[44];
        return hexagramName[44];
      } else if (hexagramNumber === "000110") {
        extraText = extraTextArray[45];
        return hexagramName[45];
      } else if (hexagramNumber === "011010") {
        extraText = extraTextArray[46];
        return hexagramName[46];
      } else if (hexagramNumber === "010110") {
        extraText = extraTextArray[47];
        return hexagramName[47];
      } else if (hexagramNumber === "011101") {
        extraText = extraTextArray[48];
        return hexagramName[48];
      } else if (hexagramNumber === "101110") {
        extraText = extraTextArray[49];
        return hexagramName[49];
      } else if (hexagramNumber === "001001") {
        extraText = extraTextArray[50];
        return hexagramName[50];
      } else if (hexagramNumber === "100100") {
        extraText = extraTextArray[51];
        return hexagramName[51];
      } else if (hexagramNumber === "110100") {
        extraText = extraTextArray[52];
        return hexagramName[52];
      } else if (hexagramNumber === "001011") {
        extraText = extraTextArray[53];
        return hexagramName[53];
      } else if (hexagramNumber === "001101") {
        extraText = extraTextArray[54];
        return hexagramName[54];
      } else if (hexagramNumber === "101100") {
        extraText = extraTextArray[55];
        return hexagramName[55];
      } else if (hexagramNumber === "110110") {
        extraText = extraTextArray[56];
        return hexagramName[56];
      } else if (hexagramNumber === "011011") {
        extraText = extraTextArray[57];
        return hexagramName[57];
      } else if (hexagramNumber === "110010") {
        extraText = extraTextArray[58];
        return hexagramName[58];
      } else if (hexagramNumber === "010011") {
        extraText = extraTextArray[59];
        return hexagramName[59];
      } else if (hexagramNumber === "110011") {
        extraText = extraTextArray[60];
        return hexagramName[60];
      } else if (hexagramNumber === "001100") {
        extraText = extraTextArray[61];
        return hexagramName[61];
      } else if (hexagramNumber === "010101") {
        extraText = extraTextArray[62];
        return hexagramName[62];
      } else if (hexagramNumber === "101010") {
        extraText = extraTextArray[63];
        return hexagramName[63];
      }
    } //hexagramNumberTohexagramName

    setTopName(topTrigramName);
    setBottomName(bottomTrigramName);

    setHexagramNameState(hexagramNumberTohexagramName());
    setHexagramExtraTextState(extraText);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div id="one" className={lineOneYin}></div>
        <div id="two" className={lineTwoYin}></div>
        <div id="three" className={lineThreeYin}></div>
        <div id="four" className={lineFourYin}></div>
        <div id="five" className={lineFiveYin}></div>
        <div id="six" className={lineSixYin}></div>

        <p id="topName" className={styles.ichingText} style={{ marginTop: 25 }}>
          {topName}
        </p>
        <p id="bottomName" className={styles.ichingText}>
          {bottomName}
        </p>
        <p
          id="hexagramName"
          className={styles.ichingText}
          style={{ marginTop: 25, lineHeight: 1 }}
        >
          {hexagramNameState}
        </p>
        <p
          id="hexagramExtraText"
          style={{ marginTop: 0, fontSize: "1.2rem", padding: 15 }}
        >
          {hexagramExtraTextState}
        </p>
      </div>
    </>
  );
};

export default Iching;
